import Navigo from 'navigo';
import lightbox from 'lightbox2';
import lightboxstyle from 'lightbox2/dist/css/lightbox.min.css';

import logoBlackSvg from '../images/logo_black.svg';
import logoBlack from '../images/logo_black.png';
import logoWhiteSvg from '../images/logo_white.svg';
import logoWhite from '../images/logo_white.png';

import initMap from '../js/map';

import  './../styl/main.styl';

import config from './../config.json';


lightbox.option({
    'albumLabel': "Изображение %1 из %2",
});

// Роутер
var root = null,
    useHash = true,
    router = new Navigo(root, useHash),
    list = [".active_about_us", ".active_our_services", ".active_project", ".active_technologies", ".active_adress", ".active_contact", ".active_cookies-info"],
    arr = [".about_us", ".our_services", ".project", ".adress", ".technologies", ".contact", ".adress_local", ".cookies-info"],
    state = {
        services: {},
    },
    slideNowServises = 1,
    slideCountServises = 0,
    navBtnIdServises = 0,
    translateWidthServises = 0,
    slideNow = 1,
    slideCount = 0,
    slideInterval = 3000,
    navBtnId = 0,
    translateWidth = 0;

function activeSections() {
    if ($('body').innerWidth() > 768) {
        console.log('works')
        $(".section_move").css("right", "50%");
        $(".section_move").css("backgroundColor", "white");
        $(".section_right").css("backgroundColor", "");
        $(".section_left").css("zIndex", "0");
        $(".section_right").css("zIndex", "1");
        $(".menu_logo").attr("src", logoBlack);
        $(".menu_logo_svg").attr("data", logoBlackSvg);
        $(".menu_telephone").css("color", "#232323");
    } else {
        $(".section_move").css("right", "0");

    }
};

function activeMenu(menu) {
    for (var i = 0; i < arr.length; i++) {
        if (arr[i] == menu) {
            $(menu).addClass("menu_on");
            $(menu).removeClass("menu_off");
        } else {
            $(arr[i]).addClass("menu_off");
            $(arr[i]).removeClass("menu_on");
        }
    }
};

function loadPage (page, className) {
    $('.list').addClass('a_white');
    $('.list').removeClass('a_black');
    activeSections();
    activeMenu('.' + className);
    $('.active_' + className).css('color', 'black');
    if(page === 'map') {
        initMap(function() {
            $(".adress").addClass('menu_off').removeClass('menu_on');
            $(".adress_local").addClass("menu_on").removeClass("menu_off");
        });
    } else if(page === 'projects') {
        if ($('body').innerWidth() > 768) {
            $(".section_move").css("right", "50%");
            $(".section_move").css("backgroundColor", "#212121");
            $(".section_left").css("zIndex", "0");
            $(".section_right").css("zIndex", "1");
            $(".section_right").css("backgroundColor", "white");
            $(".menu_logo").attr("src", logoWhite);
            $(".menu_logo_svg").attr("data", logoWhiteSvg);
            $(".list").css("color", "white");
            $(this).css("color", "#888");
            $(".menu_telephone").css("color", "white");
        } else {
            $(".section_move").css("right", "0");
        }
    }
    $(".list").css("color", "#888");
}

function isValidName () {
    var contactNameInput = $('.contact_name'),
        value = contactNameInput.val(),
        errorLabel = $('.error_contact_name'),
        isValid = true;
    if (value !== '') {
        var pattern = /^[А-Яа-яЁё\s]{2,16}$/;
        if (pattern.test(value)) {
            contactNameInput.removeClass('invalid').addClass('valid');
            errorLabel.text("");
        } else {
            contactNameInput.removeClass('valid').addClass('invalid');
            errorLabel.text('Имя должно содержать от 2 до 16 букв русского алфавита');
            isValid = false;
        }
    } else {
        contactNameInput.removeClass('valid').addClass('invalid');
        $(".error_contact_name").text("Поле имя не должно быть пустым");
        isValid = false;
    }
    return isValid;
};

function isValidEmail () {
    var emailInput = $('.contact_email'),
        value = emailInput.val(),
        errorLabel = $('.error_contact_email'),
        isValid = true;

    if (value !== '') {
        var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (pattern.test(value)) {
            errorLabel.text('');
            emailInput.removeClass('invalid').addClass('valid');
        } else {
            emailInput.removeClass('valid').addClass('invalid');
            errorLabel.text('Email указан в неправильном формате');
            isValid = false;
        }
    } else if ($('.contact_phone').val() === '') {
        emailInput.removeClass('valid').addClass('invalid');
        errorLabel.text('');
        $('.contact_phone').removeClass('valid').addClass('invalid');
        $('.error_contact_phone').text('Укажите телефон или email');
        isValid = false;
    } else {
        emailInput.removeClass('invalid valid');
        errorLabel.text('');
    }
    return isValid;
};

function isValidPhone () {
    var phoneInput = $('.contact_phone'),
        value = phoneInput.val(),
        emailInput = $('.contact_email'),
        errorLabel = $('.error_contact_phone'),
        isValid = true;
    if (value !== '') {
        if(!emailInput.val()) {
            emailInput.removeClass('invalid valid');
        }
        errorLabel.text('');
        phoneInput.removeClass('invalid').addClass('valid');
    } else if ($('.contact_email').val() === '') {
        phoneInput.removeClass('valid').addClass('invalid');
        errorLabel.text('Укажите телефон или email');
        isValid = false;
    } else {
        phoneInput.removeClass('invalid valid');
        errorLabel.text('');
    }
    return isValid;
};

function isValidMessage() {
    var messageInput = $('.input_text'),
        value = messageInput.val(),
        errorLabel = $('.error_input_text'),
        isValid = true;
    if (value === '') {
        messageInput.removeClass('valid').addClass('invalid');
        errorLabel.text('Поле сообщение не должно быть пустым');
        isValid = false;
    } else {
        messageInput.removeClass('invalid').addClass('valid');
        errorLabel.text('');
    }
    return isValid;
};

$(document).ready(function() {
    
    slideCountServises = $('.slidewrapper_services').children().length;
    slideCount = $('.slidewrapper').children().length;

    $(".logo").css("opacity", "1");
    $(".section_move").css("right", "0");
    $(".section_right").css("right", "0");

    $(".btn_map_close").click(function() {
        $(".adress").addClass('menu_on');
        $(".adress").removeClass('menu_off');
        $(".adress_local").addClass("menu_off");
        $(".adress_local").removeClass("menu_on");
    });
    
    $(".contact_name").blur(function() {
        isValidName();
    });

    $(".contact_email").blur(function() {
        isValidEmail();
    });

    $(".contact_phone").blur(function() {
        isValidPhone();
    });

    $(".input_text").blur(function() {
        isValidMessage();
    });

    $('.next_btn_services').click(function() {
        nextSlideServises(this);
    });

    $('.prev_btn_services').click(function() {
        prevSlideServises(this);
    });

    $('.slide_nav_btn_services').click(function() {
        var currentIndex = $(this).index(),
            nextIndex = currentIndex + 1,
            activeSlide = state.services.activeSlide;
        
        if (nextIndex != activeSlide) {
            var translateWidthServices = -$('.viewport_services').width() * (currentIndex);
            $('.slidewrapper_services').css({
                'transform': 'translate(' + translateWidthServices + 'px, 0)',
                '-webkit-transform': 'translate(' + translateWidthServices + 'px, 0)',
                '-ms-transform': 'translate(' + translateWidthServices + 'px, 0)',
            });
            activeSlide = nextIndex;
            slideNowServises = activeSlide;

            changeSlide(this, nextIndex, '_services');
        }
    });

    $('.next_btn').click(function() {
        nextSlide();
    });

    $('.prev_btn').click(function() {
        prevSlide();
    });

    $('.slide_nav_btn').click(function() {
        navBtnId = $(this).index();
        if (navBtnId + 1 != slideNow) {
            translateWidth = -$('.viewport').width() * (navBtnId);
            $('.slidewrapper').css({
                'transform': 'translate(' + translateWidth + 'px, 0)',
            });
            slideNow = navBtnId + 1;

            changeSlide(this, slideNow, '');
        }
    });

    $('.click_up').click(function(){
        $(".section_wrappers").animate({scrollTop: 0}, 300);
    });

    $('#contact-form').submit(function (e) {
        e.preventDefault();
        
        var resultObject = {},
            resultLabel = $('#send-result'),
            hideTimeout = function () {
                setTimeout(function() {
                    resultLabel.fadeOut(200);
                }, 10000);
            };

        resultLabel.hide();

        if(isValidMessage() && isValidPhone() && isValidEmail() && isValidName()) {
            resultLabel.text('Отправляем...').css({ 'color': '#FFF' }).fadeIn(200);
            // Обрабатываем данные формы для отправки
            $(this).serializeArray().forEach(function(item) {
                resultObject[item.name] = item.value;
            });
            $(':input[type="submit"]').prop('disabled', true);

            $.ajax({
                url: `${config.apiUrl}/mail`,
                type: 'POST',
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify(resultObject),
                headers: {
                    key: config.apiKey
                },
            })
            .done(function() {
                resultLabel.text('Сообщение успешно отправлено').css({ 'color': '#569b44' });
                $(':input[type="submit"]').prop('disabled', false);
                hideTimeout();
            })
            .fail(function() {
                resultLabel.text('Сообщение не отправлено, повторите попытку позднее').css({ 'color': '#ff0000' });
                $(':input[type="submit"]').prop('disabled', false);
                hideTimeout();
            });
        }
    });

    router
        .on('about', function () {
            loadPage('about', 'about_us');
        })
        .on('services', function () {
            loadPage('services', 'our_services');
        })
        .on('map', function () {
            loadPage('map', 'adress');
        })
        .on('technologies', function () {
            loadPage('technologies', 'technologies');
        })
        .on('contact', function () {
            loadPage('contact', 'contact');
        })
        // .on('projects', function () {
        //     loadPage('projects', 'project');
        // })
        .on('cookies-info', function () {
            loadPage('cookies-info', 'cookies-info');
        })
        .resolve();

    $('#cookie-aggree-btn').click(function(){
        $('#cookie-banner').hide();
        setCookie('cookie-aggree', 'yes', 365);
    })

    if(!getCookie('cookie-aggree')) {
        $('#cookie-banner').css({ display: 'flex' });
    }

});


function nextSlide() {
    if (slideNow == slideCount || slideNow <= 0 || slideNow > slideCount) {
        $('.next_btn').addClass('btn_off').removeClass('btn_on');
    } else {
        $('.next_btn').addClass('btn_on').removeClass('btn_off');
        $('.prev_btn').addClass('btn_on').removeClass('btn_off');

        translateWidth = -$('.viewport').width() * (slideNow);
        $('.slidewrapper').css({
            'transform': 'translate(' + translateWidth + 'px, 0)',
            '-webkit-transform': 'translate(' + translateWidth + 'px, 0)',
            '-ms-transform': 'translate(' + translateWidth + 'px, 0)',
        });
        slideNow++;
        changeSlide($('.slide_nav_btn:nth-child('+slideNow+')'), slideNow, '');
    }
}

function prevSlide() {
    if (slideNow == 1 || slideNow <= 0 || slideNow > slideCount) {
        $('.prev_btn').addClass('btn_off').removeClass('btn_on');
    } else {
        $('.next_btn').addClass('btn_on').removeClass('btn_off');
        $('.prev_btn').addClass('btn_on').removeClass('btn_off');

        translateWidth = -$('.viewport').width() * (slideNow - 2);
        $('.slidewrapper').css({
            'transform': 'translate(' + translateWidth + 'px, 0)',
            '-webkit-transform': 'translate(' + translateWidth + 'px, 0)',
            '-ms-transform': 'translate(' + translateWidth + 'px, 0)',
        });
        slideNow--;
        changeSlide($('.slide_nav_btn:nth-child('+slideNow+')'), slideNow, '');
    }
}

function changeSlide(clickedElement, nextIndex, postfix) {
    var element = $(clickedElement),
    btnService = element.find('a');
    $('.slide_nav_btn' + postfix).not(clickedElement).find('a').removeClass('nav_on').addClass('nav_off');
    element.find('a').addClass('nav_on').removeClass('nav_off');

    if(element.is(':last-child')) {
        $('.next_btn' + postfix).addClass('btn_off').removeClass('btn_on');
        $('.prev_btn' + postfix).addClass('btn_on').removeClass('btn_off');
    } else if(element.is(':first-child')) {
        $('.next_btn' + postfix).addClass('btn_on').removeClass('btn_off');
        $('.prev_btn' + postfix).addClass('btn_off').removeClass('btn_on');
    } else {
        $('.next_btn' + postfix).addClass('btn_on');
        $('.prev_btn' + postfix).addClass('btn_on');
    }

    $('.slide' + postfix).not('.slide' + postfix + ':nth-child('+ nextIndex +')').addClass('menu_off').removeClass('menu_on');
    $('.slide' + postfix + ':nth-child('+ nextIndex +')').addClass('menu_on').removeClass('menu_off');
};

function nextSlideServises(button) {
    if (slideNowServises == slideCountServises || slideNowServises <= 0 || slideNowServises > slideCountServises) {
        $('.next_btn_services').addClass('btn_off').removeClass('btn_on');
    } else {
        $('.next_btn_services').addClass('btn_on').removeClass('btn_off');
        $('.prev_btn_services').addClass('btn_on').removeClass('btn_off');

        translateWidthServises = -$('.viewport_services').width() * (slideNowServises);
        $('.slidewrapper_services').css({
            'transform': 'translate(' + translateWidthServises + 'px, 0)',
        });
        slideNowServises++;
        changeSlide($('.slide_nav_btn_services:nth-child('+slideNowServises+')'), slideNowServises, '_services');
    }
}

function prevSlideServises() {
    if (slideNowServises == 1 || slideNowServises <= 0 || slideNowServises > slideCountServises) {
        $('.prev_btn_services').addClass('btn_off').removeClass('btn_on');
    } else {
        $('.next_btn_services').addClass('btn_on').removeClass('btn_off');
        $('.prev_btn_services').addClass('btn_on').removeClass('btn_off');
        
        translateWidthServises = -$('.viewport_services').width() * (slideNowServises - 2);
        $('.slidewrapper_services').css({
            'transform': 'translate(' + translateWidthServises + 'px, 0)',
        });
        slideNowServises--;
        changeSlide($('.slide_nav_btn_services:nth-child('+slideNowServises+')'), slideNowServises, '_services');
    }
}

function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}